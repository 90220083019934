import { useCallback } from 'react'

import { LoginFlow, RecoveryFlow, RegistrationFlow, UiNode, UiText } from '@ory/kratos-client'
import { isAxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { LUMOSITY_USER_COOKIE } from '~/constants'
import { ErrorEventTypesValues } from '~/events/eventTypes'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { useLogout } from '~/hooks/useAuth'
import { useErrorNotification } from '~/hooks/useErrorNotification'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import logger from '~/utils/logger'

export type OryAuthParams = {
  csrfToken: string
  submitUrl: string
  flowId: string
  emailValue: string

  showPassword: boolean
  showGoogle: boolean
  showFacebook: boolean
  showApple: boolean

  messages: UiText[]
}

/**
 * creates a config object from the ory flow.
 */
export const extractOryAuthParams = (flow?: LoginFlow | RegistrationFlow | RecoveryFlow): OryAuthParams => {
  const authParams: OryAuthParams = {
    submitUrl: '',
    flowId: '',
    csrfToken: '',
    emailValue: '',

    showPassword: false,
    showGoogle: false,
    showFacebook: false,
    showApple: false,

    messages: [], // all messages from the flow, i.e ui.message + all ui.nodes.messages
  }

  authParams.flowId = flow?.id || ''
  authParams.submitUrl = flow?.ui?.action || ''

  const mainMessages: UiText[] = flow?.ui?.messages || []
  authParams.messages = [...mainMessages]

  const uiNodes: UiNode[] = flow?.ui?.nodes || []
  uiNodes.forEach((node) => {
    const attr = node.attributes

    if (node.messages) {
      authParams.messages.push(...node.messages) // start collecting all inner messages here
    }

    if (attr.node_type === 'input') {
      if (attr.name === 'csrf_token') {
        authParams.csrfToken = attr?.value || ''
      }

      if (attr.name === 'provider' && attr.value === 'apple') {
        authParams.showApple = true
      }

      if (attr.name === 'provider' && attr.value === 'google') {
        authParams.showGoogle = true
      }

      if (attr.name === 'provider' && attr.value === 'facebook') {
        authParams.showFacebook = true
      }

      if (attr.name === 'identifier') {
        // identifier is email
        authParams.emailValue = attr?.value || ''
      }

      if (attr.name === 'password') {
        authParams.showPassword = true
      }
    }
  })

  return authParams
}

/**
 * LUM-1292
 * https://lumoslabs.atlassian.net/wiki/spaces/BC/pages/2784460802/Handling+errors+from+Ory+SignUp+LogIn+AccountRecovery
 * https://docs.google.com/spreadsheets/d/1RWor69ueqyMmKt8SFpXEnZbOjXSOMsNy3XGb60_Uep0/edit?gid=0#gid=0
 * This function maps the ory error message id to the corresponding translation key
 */
export const oryErrorMap = (oryMessageId: UiText['id']) => {
  const oryIdMessageMap: {
    [key: UiText['id']]: string
  } = {
    4000001: 'defaultError',
    4000002: 'defaultError',
    4000004: 'defaultError',
    4000005: 'defaultError',
    4000006: 'invalidLoginCredential',
    4000007: 'invalidSignUpCredential',
    4000008: 'invalidError',
    4000009: 'systemError',
    4000027: 'invalidSignUpCredential',
    4000028: 'wrongAuthMethod',
    4000031: 'signUpEmailPasswordSame',
    4000032: 'minPasswordLength',
    4000033: 'maxPasswordLength',
    4000034: 'passwordDataBreach',
    4000035: 'systemError',
    4000036: 'systemError',
    4000037: 'systemError',
    4010001: 'unableToLogIn',
    4040001: 'unableToSignUp',
    4060001: 'invalidRecoveryCode',
    4060002: 'recoveryCodeSystemError',
    4060004: 'invalidRecoveryCode',
    4060005: 'invalidRecoveryCode',
    4060006: 'invalidRecoveryCode',
    4070002: 'defaultError',
    5000001: 'defaultError',
  } as const
  return oryIdMessageMap[`${oryMessageId}`] || undefined
}

export const useOryFlows = () => {
  const [{ [LUMOSITY_USER_COOKIE]: lumosityUserCookie }] = useCookies([LUMOSITY_USER_COOKIE])
  const router = useRouter()
  const { processOryLogout } = useLogout()
  const { showErrorNotification } = useErrorNotification()
  const { trackErrorEvent } = useTrackEvents()
  const t = useTranslationForNamespace('common.authErrors')

  /**
   * processOryFlowResponse shows snackbar for the first error message in the response
   * and returns hasError:true if there is an error in the response
   */
  const processOryFlowResponse = useCallback(
    (
      resp: RegistrationFlow | LoginFlow | RecoveryFlow,
      { flowErrorType }: { flowErrorType: ErrorEventTypesValues },
    ): { hasError: boolean; authParams: OryAuthParams } => {
      const authParams = extractOryAuthParams(resp)

      if (!authParams.csrfToken || !authParams.submitUrl || !authParams.flowId) {
        showErrorNotification('common.authErrors.defaultError')
        trackErrorEvent({
          error_type: flowErrorType,
          error_location: 'Error::processOryFlowResponse:authParamsError',
          error_message: '',
          error_details: { authParams },
        })
        return { hasError: true, authParams }
      }

      const isAccountLinkError = authParams.messages.find((message) => message?.id === 1010016) // 1010016 is type=info
      // for ory 1010016 -> "Signing in will link your account to \"abc@gmail.com\" at provider \"Google\". If you do not wish to link that account, please start a new login flow."
      if (isAccountLinkError) {
        // we have support for account linking, thus its not error.
        return { hasError: false, authParams }
      }

      // find first error message and show it to user
      const oryErrorObj: UiText | undefined = authParams.messages.find((message) => message?.type === 'error')
      if (oryErrorObj) {
        trackErrorEvent({
          error_type: flowErrorType,
          error_location: 'Error::processOryFlowResponse:OryErrorMessage',
          error_message: '',
          error_details: { messages: authParams.messages }, // all messages in the response to get more context
        })

        const { id } = oryErrorObj
        const translationKey = oryErrorMap(id)
        if (translationKey) {
          showErrorNotification(`common.authErrors.${translationKey}`)
          return { hasError: true, authParams }
        }
        logger.error('Ory flow response error - untranslated message', oryErrorObj)
        showErrorNotification('common.authErrors.defaultError')
        return { hasError: true, authParams }
      }
      return { hasError: false, authParams }
    },
    [showErrorNotification, trackErrorEvent],
  )

  const processOryApiError = useCallback(
    async (error: unknown, { flowErrorType }: { flowErrorType: ErrorEventTypesValues }): Promise<undefined> => {
      try {
        if (isAxiosError(error)) {
          const errorId = error.response?.data?.error?.id
          if (errorId === 'session_already_available') {
            if (lumosityUserCookie) {
              // ory session exists, & l2 session exists, thus session related flow like login, registration, recovery is not needed
              // move to auth-callback page, as it checks the session and redirects to home page if session exists
              // for fb-auth mobile, it exchange the cookie to session token, and deeplink it back to mobile
              await router.push('/auth-callback')
            } else {
              // ory session exists, but l2 session does not exist
              // logout ory session and create a new registration flow
              await processOryLogout()
              // as this is public page, simply reload the page to restart the ory flows after logout
              window.location.reload()
            }
            return
          }
          if (errorId === 'self_service_flow_expired') {
            // flow (login, signUp, recovery) can be expired due to inactivity
            showErrorNotification('common.authErrors.defaultError')
            return
          }

          // error from updateRegistrationFlow or updateLoginFlow or updateRecoveryFlow is an API failure,
          // thus these response object might have same error same as flow response
          const { hasError } = processOryFlowResponse(error.response?.data, { flowErrorType })
          if (hasError) {
            return
          }
          trackErrorEvent({
            error_type: flowErrorType,
            error_location: 'Error::processOryApiError::AxiosError',
            error_message: t('defaultError'),
            error_details: error.response?.data,
          })
          logger.error('NewUseCase::ErrorOryResponse', error)
        } else {
          trackErrorEvent({
            error_type: flowErrorType,
            error_location: 'Error::processOryApiError::NotAxiosError',
            error_message: t('defaultError'),
            error_details: error,
          })
        }
        showErrorNotification('common.authErrors.defaultError')
        return
      } catch (e) {
        trackErrorEvent({
          error_type: flowErrorType,
          error_location: 'Error::processOryApiError::catch',
          error_message: t('defaultError'),
          error_details: { e, error },
        })
        logger.error('Error in processOryApiError', e, error)
        showErrorNotification('common.authErrors.defaultError')
        return
      }
    },
    [lumosityUserCookie, processOryFlowResponse, processOryLogout, router, showErrorNotification, t, trackErrorEvent],
  )

  return {
    processOryFlowResponse,
    processOryApiError,
  }
}

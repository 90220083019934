import styled from 'styled-components'

import LumosIcon from '~/components/ui/LumosIcon'

const DEFAULT_TRANSITION_DURATION = '320ms'
const DEFAULT_TRANSITION_EASING_FUNCTION = 'ease-out'
const ERROR_RED = '#e9324c'

export const FormContainer = styled.div`
  width: 75%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`

export const VerticalInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const IconContainer = styled.span<{ $hidden: boolean; $isRed: boolean }>`
  ${({ $hidden }) => $hidden && 'opacity: 0% !important;'}
  ${({ $hidden }) => $hidden && 'height: 0 !important;'}
  ${({ $isRed }) => $isRed && `color: ${ERROR_RED} !important;`}
`

export const InputContainer = styled.div<{ $hasValidationError?: boolean }>`
  width: 100%;
  position: relative;
  height: 56px;

  input {
    background: white;
    border: 1px solid ${({ theme }) => theme.colors.neutral['100']};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.neutral['400']};
    width: 100%;
    height: 100%;
    padding: 1em 16px 0;
    transition: ${`border ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`};
    outline: none;
  }
  input:not(:focus):hover {
    border: 1px solid ${({ theme }) => theme.colors.neutral['200']};
  }

  input:not(:focus):hover + label {
    color: ${({ theme }) => theme.colors.neutral['300']};
  }

  input:not(:focus):hover + ${IconContainer} {
    color: ${({ theme }) => theme.colors.neutral['300']};
  }

  input:focus {
    border: 1px solid ${({ theme }) => theme.colors.neutral['400']};
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    font-size: 12px;
    opacity: 70%;
    height: 1em;
    margin-top: 8px;
  }

  input:active {
    border: 1px solid ${({ theme }) => theme.colors.neutral['400']};
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    ${({ $hasValidationError }) => $hasValidationError && `border: 1px solid ${ERROR_RED}`}
  }

  label {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    padding-left: 16px;
    color: ${({ theme }) => theme.colors.neutral['200']};
    user-select: none;
    height: 100%;
    transition: ${`color ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`height ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`opacity ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`margin-top ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`font-size ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`};
  }

  ${IconContainer} {
    position: absolute;
    right: 18px;
    top: 0;
    display: flex;
    color: ${({ theme }) => theme.colors.neutral['200']};
    align-items: center;
    height: 100%;
    transition: ${`color ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`opacity ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`},
      ${`height ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`};
  }
`

export const ValidationText = styled.div<{ $hidden: boolean }>`
  align-self: flex-start;
  margin-top: -8px;
  color: ${ERROR_RED};
  ${({ $hidden }) => $hidden && 'opacity: 0% !important;'}
  ${({ $hidden }) => $hidden && 'height: 0 !important;'}
  transition: ${`height ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_EASING_FUNCTION}`};
`

export const ClickableValidationIcon = styled(LumosIcon)`
  cursor: pointer !important;
`

import styled, { css } from 'styled-components'

type LumosIconProps = {
  Icon: SvgImage
  size: number
  color: string
  className?: string
  onClick?: () => void
}

type SvgStyles = Pick<LumosIconProps, 'size' | 'color'>
const LumosIcon: React.FC<LumosIconProps> = ({ Icon, onClick, size, color, className }) => {
  return (
    <Container className={className} size={size} color={color} onClick={onClick}>
      <Icon title={Icon.name} />
    </Container>
  )
}

const Container = styled.span<SvgStyles>`
  ${({ size, color }) => svgStyles({ size, color })}
`
const svgStyles = ({ size, color }: SvgStyles) => {
  return css`
    svg {
      height: ${size}px;
      width: ${size}px;

      path {
        fill: ${color} !important;
      }
    }
  `
}
const StyledLumosIcon = styled(LumosIcon)<LumosIconProps>`
  ${(props) => svgStyles(props)}
`
export default StyledLumosIcon

import React from 'react'

import { BodyText2, Caption1SemiBold } from '@lumoslabs/lumosity-storybook'

import {
  ClickableValidationIcon,
  IconContainer,
  InputContainer,
  ValidationText,
} from '~/components/auth/FormStyledComponents'
import LumosIcon from '~/components/ui/LumosIcon'

type ValidatableInputProps = {
  hasValidationError: boolean
  value: string
  id: string
  type: string
  labelString: string
  Icon: SvgImage
  validationErrorMessage?: string
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void
  onValidationIconClick?: () => void
  alwaysShowIcon?: boolean
}
const ERROR_RED = '#e9324c'
export const ValidatableInput: React.FC<ValidatableInputProps> = ({
  hasValidationError,
  id,
  value,
  type,
  onChange,
  onBlur,
  onFocus,
  onValidationIconClick,
  labelString,
  Icon,
  validationErrorMessage,
  alwaysShowIcon,
}) => {
  return (
    <>
      <InputContainer $hasValidationError={hasValidationError}>
        <BodyText2>
          <input
            id={id}
            value={value}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder=' '
          />
          <label htmlFor={id}>{labelString}</label>
          <IconContainer $isRed={hasValidationError} $hidden={alwaysShowIcon ? false : !hasValidationError}>
            {onValidationIconClick ? (
              <ClickableValidationIcon
                size={24}
                color={hasValidationError ? ERROR_RED : 'black'}
                onClick={onValidationIconClick}
                Icon={Icon}
              />
            ) : (
              <LumosIcon Icon={Icon} size={24} color={hasValidationError ? ERROR_RED : 'black'} />
            )}
          </IconContainer>
        </BodyText2>
      </InputContainer>
      <ValidationText $hidden={!hasValidationError}>
        <Caption1SemiBold>{validationErrorMessage}</Caption1SemiBold>
      </ValidationText>
    </>
  )
}

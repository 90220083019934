import { useCallback } from 'react'

import { BodyText2, BodyText2SemiBold } from '@lumoslabs/lumosity-storybook'
import Trans from 'next-translate/Trans'
import styled from 'styled-components'

import { UserHelpCenterUrl } from '~/constants'
import { useSnackbar } from '~/libs/notistack'

export const useErrorNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  const showErrorNotification = useCallback(
    (i18nKey: string) => {
      enqueueSnackbar(
        <Trans
          i18nKey={i18nKey}
          components={{
            p: <BodyText2 as='p' />,
            b: <BodyText2SemiBold />,
            HelpLink: <ErrorLink href={UserHelpCenterUrl} />,
            LoginLink: <ErrorLink href={'/login'} />,
            SignUpLink: <ErrorLink href={'/signup'} />,
            RecoverPasswordLink: <ErrorLink href={'/account-recovery'} />,
          }}
        />,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          persist: false,
        },
      )
    },
    [enqueueSnackbar],
  )
  return { showErrorNotification }
}

const ErrorLink = styled.a`
  text-decoration: underline;
`
